@import url(//fonts.googleapis.com/css?family=Lato:300:400);

:root {
  /* Customizable variables */
  --wave-color-1: rgba(5, 182, 211, 0.7);
  --wave-color-2: rgba(5, 182, 211, 0.5);
  --wave-color-3: rgba(5, 182, 211, 0.3);
  --wave-color-4: rgb(5, 182, 211);
  --wave-height: 15vh; /* Default wave height */
  --content-height: 20vh;
}
body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
  height: 100%; /* Ensure body takes full viewport height */
}

.header {
  position: relative;

  text-align: center;
  background: linear-gradient(
    60deg,
    rgba(34, 20, 92, 0) 0%,
    rgba(0, 98, 111, 0) 100%
  );
  color: white;
  overflow: hidden; /* Prevent overflow from waves */
}

.waves {
  position: absolute; /* Ensure it's aligned with the bottom of the header */
  bottom: 0;
  width: 100%;
  height: var(--wave-height);
}
.flippedWave {
  height: 100%;
}

.content {
  position: relative;
  text-align: center;
  height: 20vh; /* Adjust content height if necessary */
  font-size: 65px;
  font-weight: 900;
}
.header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
}
/* Animation remains unchanged */
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  fill: var(--wave-color-1); /* Use customizable variable */
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  fill: var(--wave-color-2); /* Use customizable variable */
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  fill: var(--wave-color-3); /* Use customizable variable */
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  fill: var(--wave-color-4); /* Use customizable variable */
  animation-delay: -5s;
  animation-duration: 20s;
}

h1 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 48px;
}

p {
  font-family: "Lato", sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: #333333;
}

.inner-header {
  height: 65vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex {
  /* Flexbox for containers */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/* Shrinking for mobile */
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  .content {
    height: 30vh;
  }

  h1 {
    font-size: 24px;
  }
}

.ysabeau-sc {
  font-family: "Ysabeau SC", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 800;
}

.manrope {
  font-family: "Manrope", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.typewriter {
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em;
  /* Adjust as needed */
  animation:
    typing 3.5s steps(40, end),
    blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 30%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: orange;
  }
}

.flip-y {
  transform: scaleY(-1);
}

.text-center {
  text-align: center;
}

.mt-16 {
  margin-top: 4rem;
}

.timeline-container {
  width: 80%;
  margin: 50px auto;
  position: relative;
  padding: 20px 0;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 30px;
  border-left: 4px solid rgb(5, 182, 211);
  position: relative;
}

.timeline-item::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 20px;
  width: 16px;
  height: 16px;
  background: rgb(0, 122, 141);
  border-radius: 50%;
  border: 2px solid white;
  z-index: 1;
}

.timeline-date {
  color: #6b7280;
  font-weight: bold;
  margin-bottom: 10px;
}

.timeline-content {
  background: #f9fafb;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
}

.timeline-title {
  font-size: 20px;
  color: #1f2937;
  margin: 0 0 10px 0;
}

.timeline-description {
  color: #4b5563;
  margin: 0;
}
