.underline-none {
  text-decoration: none !important;
  color: inherit !important;
}
.atma-medium {
  font-family: "Atma", serif;
  font-weight: 800;
  font-style: normal;
}
.name-gradient {
  background: linear-gradient(to right, rgb(5, 182, 211), #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
