.bg {
  background-color: rgb(5, 182, 211);
}
.ysabeau-sc {
  font-family: "Ysabeau SC", serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.manrope {
  font-family: "Manrope", serif;
  font-optical-sizing: auto;
  font-style: normal;
}
